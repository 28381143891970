<template>
	<div class="page">
		<div class="main">
			<van-row>
				<van-rol span="24" style="font-weight: bold;">{{translate('risk_assessment_results')}}：</van-rol>
			</van-row>
			<van-row class="risk" :style="'background-image: url(' + bg_level + ')'">
				<van-col span="24">{{translate('your_risk_type')}}</van-col>
				<van-col span="24" class="name">"{{ risk_name }}"</van-col>
			</van-row>

			<van-row class="intro">
				<van-col span="24" class="desc">{{ risk_intro }}</van-col>
			</van-row>
		</div>

		<van-row style="margin-top: 1rem;">
			<van-button color="#FB6B23" block @click="reExam" >{{translate('retest')}}</van-button>
		</van-row>		
	</div>
</template>

<script>
	export default {
		name: 'risk_level',
		data() {
			return {

				token: "",

				bg_level: require("../../assets/images/bg_level.png"),

				id: "",
				risk_name: "",
				risk_intro: "",

			}
		},
		mounted() {

			this.token = sessionStorage.getItem('token')

			this.id = this.$route.query.id

			this.onLoadData()
		},
		methods: {
			onLoadData(){
				//根据TOKEN获取信息
				this.$axios({
					method: 'post',
					url: 'fund_risk/level',
					data: {
						id: this.id
					}
				}).then ((res) => {
					if (res.success) {

						this.risk_name = res.data.name
						this.risk_intro = res.data.intro
						
					} else {
						this.$toast.fail(res.error_text)
					}
				})

			},

			reExam() {
				this.$router.push('/risk/exam?token=' + this.token) 
			}
		}
	}
</script>


<style scoped>

.page {
	background-color: #EFEFEF;
	padding: 1rem;
}
.main {
	width: 100%;
	background-color: #FFFFFF;
	padding: 1rem;
	border-radius: .5rem;
	box-sizing: border-box;
}
.risk {
	width: 8rem;
	height: 8rem;
	text-align: center;
	color: #6C6C6C;
	background-repeat: no-repeat;
	background-size: 8rem;
	background-position: center;
	margin: 2rem auto;
	padding-top: 3rem;
}
.risk .name {
	font-size: 2rem;
	color: #FB6B23;
	margin-top: .5rem;
}
.intro {
	font-size: .8rem;
	color: #6C6C6C;
}
.footerBtn {
	width: 100%;
	position: fixed;
	bottom: 0;
	left: 0;
	padding: 1rem;
	box-sizing: border-box;
}
</style>